@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/css/AntDesign.css";

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}

	/* button, [type='button'], [type='reset'], [type='submit']{
		background-color: #65a30d;
	} */
}

@layer components {
	
	.app-name {
		@apply font-bold uppercase text-brand-600 dark:text-brand-200;
	}
	
	.menu-icon, .menu-label{
		@apply font-medium text-gray-800 dark:text-white;
	}
	
	.menu-icon.active, .menu-label.active{
		@apply font-bold text-theme-500 dark:text-theme-300;
	}
	
	.card-icon{
		@apply h-6 w-6 text-theme-500 dark:text-theme-400;
	}
	
	.card-title {
		@apply text-xl font-bold text-theme-600 dark:text-theme-300;	
	}
	
	.table-header{
		@apply border-b border-gray-200 pb-[10px] text-start dark:!border-theme-600;
	}

	.panel-main-wrapper{
		@apply mx-[12px] h-full flex-none transition-all md:pr-2 w-full max-w-[990px];
	}

	.ant-btn,
	.btn {
		@apply h-auto px-5 py-3 font-medium rounded-xl text-base text-white transition duration-200 hover:text-white hover:shadow-lg hover:shadow-brandLinear/50;
	}
	
	.ant-btn-link,
	.ant-btn-primary,
	.btn-primary {
		@apply btn bg-gradient-to-br from-brandSecondary to-brandLinear hover:shadow-brandLinear/50;
	}
		
	.btn-secondary {
		@apply btn bg-gradient-to-br from-brandSecondary to-brandLinear;
	}
	
	.btn-success {
		@apply btn bg-gradient-to-br from-brandSecondary to-brand-200;
	}
	
	.btn-danger {
		@apply btn bg-gradient-to-br from-red-400 to-red-800;
	}
	
	.ant-btn-sm,
	.btn-small {
		@apply btn px-4 py-2 font-normal;
	}
	
	.ant-btn:disabled,
	.btn.disabled {
		@apply from-disabledPrimary to-disabledPrimary hover:shadow-none;
	}

	a.crud-button{
		@apply btn btn-primary mx-3 min-w-[100px] text-center;
	}
	
	a.btn {
		@apply h-[48px] text-white inline-block hover:text-white;
	}
	
	button > a,
	a.btn{
		@apply hover:text-white;
	}

	.form-column-4{
		@apply grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4;
	}

	.form-column-3{
		@apply grid grid-cols-1 md:grid-cols-3;
	}

	label.form-section-label{
		@apply font-bold dark:text-white;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

.form-item-label {
	@apply font-bold dark:text-white;
}
