
@tailwind components;

@layer components {
	.light{
		.ant-pagination .ant-pagination-item-active{
			@apply text-brand-600 border-brand-600;
		}

		.ant-pagination .ant-pagination-item-active a{
			@apply text-brand-600;
		}

		.ant-form-item-control-input-content{
			button, [type='button'], [type='reset'], [type='submit'] {
				@apply bg-gray-800/50;
			}

			.ant-switch.ant-switch-checked{
				@apply bg-theme-800;
			}
		}
	}

	/**
	ANT DESIGN dark mode
	*/
	.dark {
		.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
			@apply text-white hover:bg-gray-50/20;
		}

		.ant-table{
			@apply !bg-intsamis-600/20;
		}

		.ant-table-wrapper .ant-table-thead >tr>th, 
		.ant-table-wrapper .ant-table-thead >tr>td {
			@apply !bg-intsamis-900/50;
		}

		.ant-table-wrapper .ant-table-thead >tr>th, 
		.ant-table-wrapper .ant-table-thead >tr>td,
		.ant-table-wrapper .ant-table-tbody >tr >th, 
		.ant-table-wrapper .ant-table-tbody >tr >td,
		.ant-table-wrapper .ant-table-filter-trigger,
		.ant-table-wrapper .ant-table-column-sorter{
			@apply text-white;
		}

		.ant-table-wrapper .ant-table-tbody >tr:hover >td{
			@apply bg-gray-50/20;
		}

		.ant-pagination .ant-pagination-prev button, 
		.ant-pagination .ant-pagination-next button,
		.ant-pagination .ant-pagination-total-text,
		.ant-pagination .ant-pagination-item a{
			@apply text-white;
		}

		.ant-pagination .ant-pagination-disabled .ant-pagination-item-link, 
		.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link{
			@apply text-gray-800;
		}

		.ant-pagination .ant-pagination-item-active{
			@apply bg-gray-800 border-white;
		}
	}

	.hidden-tabs .ant-tabs-nav {
		@apply hidden;
	}

	.ant-form-vertical .label-hidden .ant-form-item-label{
		@apply hidden;
	}
}
